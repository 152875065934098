<template>
  <nav>
    <v-app-bar v-bind="mobileProps" :clipped-left="!isMobile" dark color="#1d1d21" height="65" elevation="0" style="z-index: 15; border-bottom: solid 1px #101012;">
      <v-row>
        <v-col>
        <v-img @click="$router.push('/', () => {})" max-height="25px" :max-width="isMobile2 < 400 ? '180px' : '260px'" :min-width="isMobile2 < 400 ? '180px' : '260px'" class="mt-8 ml-n3" :style="$store.state.authenticated ? 'margin-top: 20px !important;' : 'margin-top: 20px !important;'" src="@/assets/test.png" style="position: absolute; cursor: pointer;"></v-img>
        </v-col>

        <div v-if="isMobile2 > 1100">
          <v-btn elevation="0" class="my-7 btn" :ripple="false" text plain @mousedown.middle="goToDashboardNewTab()" @click="goToDashboard()">Dashboard</v-btn>
          
          <v-btn elevation="0" class="my-7 btn" :ripple="false" text plain @mousedown.middle="goToCommandsNewTab()" @click="goToCommands()">Documentation</v-btn>
          <v-btn elevation="0" class="my-7 btn" :ripple="false" text plain  @click="goToSupportServer()">Support Server</v-btn>
          <v-btn elevation="0" class="my-7 btn" :ripple="false" text plain @click="goToBotInvite()">Invite Bot</v-btn>
          <v-btn elevation="0" class="my-7 btn" :ripple="false" color="#AAAEFB" text plain @mousedown.middle="goToRaidHelperPremiumNewTab()" @click="goToRaidHelperPremium()"><span class="prem">Premium</span></v-btn>
        </div>

          <div v-if="isMobile2 < 1100 && isMobile2 > 750">
          <v-btn elevation="0" class="mx-n1 my-7 btn" :ripple="false" text plain @mousedown.middle="goToDashboardNewTab()" @click="goToDashboard()">Dashboard</v-btn>
          
          <v-btn elevation="0" class="mx-n1 my-7 btn" :ripple="false" text plain @mousedown.middle="goToCommandsNewTab()" @click="goToCommands()">Docs</v-btn>
          <v-btn elevation="0" class="mx-n1 my-7 btn" :ripple="false" text plain @click="goToSupportServer()">Support</v-btn>
          <v-btn elevation="0" class="mx-n1 my-7 btn" :ripple="false" text plain @click="goToBotInvite()">Invite</v-btn>
          <v-btn elevation="0" class="mx-n1 my-7 btn" :ripple="false" color="#AAAEFB" text plain @mousedown.middle="goToRaidHelperPremiumNewTab()" @click="goToRaidHelperPremium()"><span class="prem">Premium</span></v-btn>
        </div>

        <div>

          <v-menu v-if="isMobile2 < 750" dark bottom offset-y close-delay="500" nudge-bottom="27" >
            <template v-slot:activator="{ on, attrs }">
              <v-btn elevation="0" :ripple="false" text plain class="my-7 btn mr-2" v-bind="attrs" v-on="on">
                  <v-icon>mdi-menu</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <v-btn elevation="0" :ripple="false" block text @mousedown.middle="goToDashboardNewTab()" @click="goToDashboard()" class="btn font-weight-bold">Dashboard</v-btn>
              </v-list-item>



              <v-list-item>
                <v-btn elevation="0" :ripple="false" block text @mousedown.middle="goToCommandsNewTab()" @click="goToCommands()" class="btn font-weight-bold">Documentation</v-btn>
              </v-list-item>
              <v-list-item>
                <v-btn elevation="0" :ripple="false" block text @click="goToSupportServer()" class="btn font-weight-bold">Support Server</v-btn>
              </v-list-item>
              <v-list-item>
                <v-btn elevation="0" :ripple="false" block text @click="goToBotInvite()" class="btn font-weight-bold">Invite Bot</v-btn>
              </v-list-item>
              <v-list-item>
                <v-btn elevation="0" :ripple="false" block text @mousedown.middle="goToRaidHelperPremiumNewTab()" @click="goToRaidHelperPremium()" class="btn blue--text prem font-weight-bold">Premium</v-btn>
              </v-list-item>
            </v-list>
          </v-menu>

            <v-btn elevation="0" v-if="$store.state.authenticated" :ripple="false" text plain width="40px" class="my-7 btn" @click="logoutDiscord">
                                <v-tooltip transition="v-scale-transition" color="#0f0f0f" bottom left>
                  <template v-slot:activator="{ on, attrs }">
                <v-avatar v-bind="attrs" v-on="on" class="mx-3" width="40" height="40">
                  <img v-if="!$store.state.discordUser.avatar.endsWith('null')" :src="$store.state.discordUser.avatar" alt="DiscordAvatar" />
                  <v-icon v-if="$store.state.discordUser.avatar.endsWith('null')">mdi-account-circle</v-icon>
                </v-avatar>
                   </template><span>Logout</span></v-tooltip>
              </v-btn>                  
           
        </div>
      </v-row>
    </v-app-bar>

  </nav>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      isMobile: false,
      isMobile2: false,
      drawer: false,
      chosenDiscord: 22,
      loading: false,
    };
  },
  beforeDestroy() {
    if (typeof window === "undefined") return;
    window.removeEventListener("resize", this.onResize, { passive: true });
  },
  methods: {

    fetchMutuals() {
      this.fetchDiscordInfo;
    },

    async deleteGuild() {
      localStorage.removeItem("chosenDiscord");
    },

    truncate(input) {
      if (input.length > 14) {
        return input.substring(0, 14) + "..";
      }
      return input;
    },

    goToDiscordRedirect() {
      window.location.href = this.$store.state.url + "/api/auth/discord";
    },

    goToRaidHelperPremium() {
			 	this.$router.push('/premium/', () => {});
    },

    goToRaidHelperPremiumNewTab() {
      window.open("https://raid-helper.dev/premium/", "_blank");
    },

    goToBotInvite() {
      window.open(
        "https://discord.com/oauth2/authorize?client_id=579155972115660803&permissions=403995880640&scope=bot%20applications.commands",
        "_blank"
      );
    },

    goToDashboard() {
      if (!this.$store.state.authenticated) {
        window.location.href = this.$store.state.url + "/api/auth/discord";
        return;
      }
        this.$router.push('/dashboard/', () => {});
    },

    goToDashboardNewTab() {
      window.open("https://raid-helper.dev/dashboard/", "_blank");
    },

    goToCommands() {
			 	this.$router.push('/documentation/commands/', () => {});
    },

    goToCommandsNewTab() {
      window.open("https://raid-helper.dev/documentation/documentation/commands/", "_blank");
    },

    goToTools() {
			 	this.$router.push('/tools/', () => {});
    },

    goToToolsNewTab() {
      window.open("https://raid-helper.dev/tools/", "_blank");
    },

    goToSupportServer() {
      window.open("https://discord.gg/7Edsfku", "_blank");
    },

    onResize() {
      this.isMobile = window.innerWidth < 800;
      this.isMobile2 = window.innerWidth;
    },

    hasClasses() {
      try {
        return (
          this.$store.state.formAnswers.template.classes !== undefined &&
          this.$store.state.formAnswers.template.classes !== "" &&
          this.$store.state.formAnswers.template.classes.length > 0
        );
      } catch {
        return false;
      }
    },

    hasRoles() {
      try {
        return (
          this.$store.state.formAnswers.template.roles !== undefined &&
          this.$store.state.formAnswers.template.roles !== "" &&
          this.$store.state.formAnswers.template.roles.length > 0
        );
      } catch {
        return false;
      }
    },

    hasOptOut() {
       try {
				return (
          this.$store.state.formAnswers.template.id !== 23 &&
          this.$store.state.formAnswers.template.id !== 24 && 
          this.$store.state.formAnswers.template.id !== 25
				);
       } catch {
        return true;
      }
			},

    closeDrawerMobile(){
      if (this.isMobile) {
        this.drawer = false
        
      } 
    },

    logoutDiscord() {
      let urlString = "/api/discord/logout"
      if (this.$store.state.url == "http://localhost:3001" || this.$store.state.url == "https://raid-helper.dev") {
          urlString = "/api/discord/logout/" + localStorage.getItem('accessToken').slice(1,-1)
      }
      this.$store.commit("setAuthenticated", false);
      axios
        .get(this.$store.state.url + urlString, {withCredentials: true})
        .then((res) => {
          this.$store.commit("setChosenDiscord", null);
          this.$store.commit("setDiscordUser", null);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
			    this.$store.state.discordUser = undefined;
			    this.$store.state.discordChannels = undefined;
          localStorage.removeItem("discordUser");
          localStorage.removeItem("lastPage");
          localStorage.removeItem("chosenDiscord");
          localStorage.removeItem("accessToken");
          if (this.$route.name == "Landingpage") {
            location.reload();
          } else {
            this.$router.push("/");
          }
          
		 
        });
    },
  },

  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },

  computed: {
    ...mapActions(["fetchDiscordInfo"]),
    options() {
      return {
        duration: 250,
        offset: 40,
        easing: "easeInOutQuad",
      };
    },
	
    mobileProps() {
      if (this.isMobile) {
        this.drawer=false
        return {
          app: true,
        };
      } else {
        return {
          app: true,
        };
      }
    },
    navBarProp(){
      if (this.$store.state.authenticated === true) {
        return{
          class: 'mt-10',
        }
      } else {
        return{
          class: 'mt-7',
        }
      }
    },
    raidLogoProp(){
      if (this.$store.state.authenticated === true) {
        return{
          class: "d-inline-block mt-9",
        }
        } else {
          return{
            class: "d-inline-block mt-6",
          }
          
        }
    }
  },
};
</script>

<style scoped>
.btn {
  text-transform: unset !important;
  border: 1px solid transparent;
}

.sevrerbtn:hover {
  border: 1px solid rgb(91, 216, 112);
}

.theme--dark.v-list {
  background-color: #1d1d21;
}


</style>